import 'bootstrap/dist/css/bootstrap.css'

import React from 'react';
import {
    Container,
} from 'reactstrap';

import '../icofont.min.css';

// Web theme color root css
import '../components/colors.css';

import '../Terms.css';
import Tdsheader from "../components/Tdsheader";

import '../App.css';
import '../responsive.css';
import Tdscontact from "../components/Tdscontact";
import Tdsfooter from "../components/Tdsfooter";

export default function Safety() {
    return (
        <div className={"App"}>
            {/* Navbar  */}
            <Tdsheader active={"safety"} isHome={false} />

            <div className={"section"} id={"safety"}>
                <h2 className={"heading mt-5 mb-1 ml-3 ml-sm-5"}>Technical & Safety Warnings and Advices</h2>
                <br/><br/>

                <Container>
                    {/* First */}
                    <h4>Important Safety Advice to Employers of Automotive Repair Shops</h4>
                    <p>
                        <strong>Employee safety responsibility is the most important aspect that distinguishes employers from self-employed people.</strong>
                        <br/>
                        As much as proper tools make work easier and faster, defective tools can cause waste.
                        Financial losses, although are severe, can be compensated, but how can human losses be compensated?
                        <br/>
                        Loss and damage for self-employed people in the occurrence of occupational accidents are just limited to the loss of part of their health or property;
                        however, the loss of life and property caused by the accident for the workers who are employed by the employers has unpleasant consequences of civil responsibilities for the managers and employers.
                        <br/>
                        Despite safety training, since the inexperience of young employees increases the rate of negligence or mistake occurrence,
                        accident prevention and taking the necessary actions to keep employees safe is one of the undeniable responsibilities of employers, therefore:
                        <br/>
                        <strong className={"text-danger"}>An experienced and professional employer continuously ensures that the quality of operation and the safety level of the workshop equipment used by the employees is in the best condition.</strong>
                        <br/><br/>
                        <strong>Opal Hydraulic</strong> has always supported distinguished managers and employers in fulfilling their duties.
                    </p>
                    <br/><br/>

                    {/* Second */}
                    <h4>Important Safety Advice to Drivers and Transporters</h4>
                    <p>
                        <strong className={"text-danger"}>Dear driver, vehicle alarm mechanism:</strong>
                        <ul>
                            <li>• Never warns of a puncture or low pressure in a spare tire.</li>
                            <li>• Does not report deficiencies in tools and essential signs to the driver when a puncture occurs.</li>
                            <li>• Does not notify the driver that the hydraulic jack is defective or unusable.</li>
                        </ul>
                        <strong className={"text-danger"}>So as a professional driver before driving:</strong>
                        <ul>
                            <li>• Make sure that the car spare tire has the right air pressure.</li>
                            <li>• Make sure that the tools and signs needed to change a flat tire are available.</li>
                            <li>• Make sure the car's hydraulic jack is ready for use.</li>
                        </ul>
                        The use of defective hydraulic jacks has always caused financial and human losses,
                        but do you know that the risk for drivers is twice as much as the risk for ordinary users of defective hydraulic jacks?
                        <br/><br/>
                        <strong className={"text-info"}>Opal Hydraulic has always supported transportation sector in fulfilling their duties.</strong>
                    </p>
                    <br/><br/>

                    {/* Second */}

                </Container>
            </div>

            {/* Contact Us Section Start  */}
            <div className="section contactus grapping" id="contact">
                <Tdscontact />
            </div>

            {/* Footer  Section Start */}
            <div className="">
                <Tdsfooter/>
            </div>
        </div>
    );
}